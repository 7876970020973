<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Benutzer</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div class="content-wrap">
            <header class="content-header">
              <h3>Stammdaten</h3>
            </header>
            <div class="content-body">
              <form @submit="update_user" class="form" method="post">
                <div class="form-wrap" style="margin-bottom: 0">
                  <label for="email">E-Mail</label>
                  <input v-model="user.email" class="form-input" type="email" name="email" id="email" />
                </div>

                <div class="row row-gutter-20" style="margin-top: 20px">
                  <div class="col-8">
                    <div class="form-wrap">
                      <label for="first_name">{{ $t("global.firstname") }}</label>
                      <input v-model="user.first_name" class="form-input" type="first_name" name="first_name" id="first_name" />
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-wrap">
                      <label for="last_name">{{ $t("global.lastname") }}</label>
                      <input v-model="user.last_name" class="form-input" type="last_name" name="last_name" id="last_name" />
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-wrap">
                      <label for="full_name">{{ $t("global.name") }}</label>
                      <input v-model="user.full_name" class="form-input" type="full_name" name="full_name" id="full_name" />
                    </div>
                  </div>
                </div>

                <div class="row row-gutter-20">
                  <div class="col-16">
                    <div class="form-wrap">
                      <label for="street_name">{{ $t("global.street") }}</label>
                      <input v-model="user.street_name" class="form-input" type="street_name" name="street_name" id="street_name" />
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-wrap">
                      <label for="street_number">{{ $t("global.housenumber") }}</label>
                      <input v-model="user.street_number" class="form-input" type="street_number" name="street_number" id="street_number" />
                    </div>
                  </div>
                </div>

                <div class="row row-gutter-20">
                  <div class="col-8">
                    <div class="form-wrap">
                      <label for="zip_code">{{ $t("global.postalcode") }}</label>
                      <input v-model="user.zip_code" class="form-input" type="zip_code" name="zip_code" id="zip_code" />
                    </div>
                  </div>
                  <div class="col-16">
                    <div class="form-wrap">
                      <label for="city">{{ $t("global.city") }}</label>
                      <input v-model="user.city" class="form-input" type="city" name="city" id="city" />
                    </div>
                  </div>
                </div>

                <div class="form-wrap">
                  <label for="balance">{{ $t("global.balance") }}</label>
                  <input v-model="user.balance" class="form-input" type="balance" name="balance" id="balance" />
                </div>

                <div class="form-wrap select-wrap">
                  <label for="industry">Branche auswählen</label>
                  <select v-model="industry" v-on:change="onChange" class="select-wrap">
                    <option v-for="ind in industries" v-bind:value="ind.id" v-bind:key="ind.id">
                      {{ ind.name }}
                    </option>
                  </select>
                </div>

                <div class="form-wrap select-wrap" v-if="industry">
                  <label for="subIndustry">Unterbranche auswählen</label>
                  <select v-model="subIndustry" class="select-wrap">
                    <option v-for="sub in subIndustries" v-bind:value="sub.id" v-bind:key="sub.id">
                      {{ sub.name }}
                    </option>
                  </select>
                </div>

                <div class="form-wrap select-wrap">
                  <label for="referred_by">Geworben von</label>
                  <select v-model="user.referred_by" class="select-wrap">
                    <option v-for="user in users" v-bind:value="user.uuid" v-bind:key="user.uuid">
                      {{ user.last_name }}, {{ user.first_name }} ({{ user.email }})
                    </option>
                  </select>
                </div>

                <p>Affiliate Link: https://oohlemon.com?ref={{ user.uuid }}</p>

                <p style="margin-bottom: 0; text-align: right">
                  <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round button-100" />
                </p>
              </form>
            </div>
          </div>
          <div class="content-wrap">
            <header class="content-header">
              <h3>Anzeigen</h3>
            </header>
            <div class="content-body">
              <div v-if="ads_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Datei</th>
                      <th>{{ $t("global.name") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="advertisment in advertisments" v-bind:key="advertisment.uuid">
                      <td width="5%">
                        <span v-if="advertisment.status == 'creating'" class="status status-yellow"></span>
                        <span v-if="advertisment.status == 'available'" class="status status-green"></span>
                      </td>
                      <td width="20%">
                        <a :href="advertisment.asset_file" target="_blank"><img :src="advertisment.thumbnail" style="max-height: 69px" /></a>
                      </td>
                      <td width="85%">
                        {{ advertisment.name }}
                      </td>
                      <td width="10%" style="text-align: right">
                        <!--<router-link :to="'/admin/advertisments/'+advertisment.uuid" class="campaign-wrap">
                          <span class="material-icons" style="color:#444">create</span>
                        </router-link>-->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ $t("global.campaigns") }}</h3>
            </header>
            <div class="content-body">
              <div v-if="loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>{{ $t("global.name") }}</th>
                      <th>Budget</th>
                      <th>Account</th>
                      <th>Stats</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="campaign in campaigns" v-bind:key="campaign.uuid">
                      <td width="5%">
                        <span v-if="campaign.status == 'draft'" class="status status-grey"></span>
                        <span v-if="campaign.status == 'creating'" class="status status-grey"></span>
                        <span v-if="campaign.status == 'created'" class="status status-grey"></span>
                        <span v-if="campaign.status == 'pending'" class="status status-yellow"></span>
                        <span v-if="campaign.status == 'running'" class="status status-green"></span>
                        <span v-if="campaign.status == 'stopped'" class="status status-red"></span>
                        <span v-if="campaign.status == 'paused'" class="status status-red"></span>
                        <span v-if="campaign.status == 'blocked'" class="status status-red"></span>
                        <span v-if="campaign.status == 'deleted'" class="status status-black"></span>
                      </td>
                      <td width="20%">
                        <div v-if="campaign.advertisment">
                          <div v-if="campaign.advertisment.thumbnail">
                            <a :href="campaign.advertisment.asset_file" target="_blank"
                              ><img :src="campaign.advertisment.thumbnail" style="max-width: 100%"
                            /></a>
                          </div>
                          <div v-else>
                            <a :href="campaign.advertisment.asset_file" target="_blank">
                              <img :src="require('../../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td width="25%">
                        <router-link :to="'/admin/campaigns/' + campaign.uuid" style="color: #444; font-weight: 600">{{ campaign.name }}</router-link>
                      </td>
                      <td width="10%">
                        {{
                          new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(campaign.budget)
                        }}
                      </td>
                      <td width="20%">
                        <router-link :to="'/admin/users/' + campaign.user.uuid" style="color: #444; font-weight: 600"
                          >{{ campaign.user.first_name }} {{ campaign.user.last_name }}</router-link
                        >
                      </td>
                      <td width="20%">{{ campaign.impressions }} | {{ campaign.qr_scan_count }}</td>
                      <td width="10%" style="text-align: right">
                        <router-link :to="'/admin/campaigns/' + campaign.uuid" class="campaign-wrap">
                          <span class="material-icons" style="color: #444">create</span>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>Bildschirme</h3>
            </header>
            <div class="content-body">
              <div v-if="devices_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>{{ $t("global.name") }}</th>
                      <th>Auth Code</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="device in devices" v-bind:key="device.uuid">
                      <td width="5%">
                        <span v-if="device.status == 'created'" class="status status-yellow"></span>
                        <span v-if="device.status == 'linked'" class="status status-yellow"></span>
                        <span v-if="device.status == 'located'" class="status status-yellow"></span>
                        <span v-if="device.status == 'running'" class="status status-green"></span>
                        <span v-if="device.status == 'deleted'" class="status status-red"></span>
                      </td>
                      <td width="10%">
                        <img :src="device.device_image" style="max-height: 82px; display: block" />
                      </td>
                      <td width="25%">{{ device.name }}</td>
                      <td width="15%">{{ device.auth_code }}</td>
                      <td width="10%" style="text-align: right">
                        <router-link :to="'/admin/displays/' + device.uuid" class="campaign-wrap">
                          <span class="material-icons" style="color: #444">create</span>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ $t("global.schedules") }}</h3>
            </header>
            <div class="content-body">
              <div v-if="schedules_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("global.name") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="schedule in schedules" v-bind:key="schedule.uuid">
                      <td>{{ schedule.name }}</td>
                      <td style="text-align: right">
                        <router-link :to="'/admin/schedules/' + schedule.uuid" class="campaign-wrap">
                          <span class="material-icons" style="color: #444">create</span>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ $t("global.playlists") }}</h3>
            </header>
            <div class="content-body">
              <div v-if="playlists_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("global.name") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="playlist in playlists" v-bind:key="playlist.uuid">
                      <td>{{ playlist.name }}</td>
                      <td style="text-align: right">
                        <router-link :to="'/admin/playlists/' + playlist.uuid" class="campaign-wrap">
                          <span class="material-icons" style="color: #444">create</span>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      search_phrase: "",
      errors: [],
      user: {
        street_name: "",
        street_number: "",
        zip_code: "",
        city: "",
      },
      industries: [],
      users: [],
      campaigns: [],
      advertisments: [],
      devices: [],
      loading: true,
      devices_loading: true,
      ads_loading: true,
      playlists_loading: true,
      schedules_loading: true,
      schedules: [],
      playlists: [],
      industry: "",
      subIndustries: [],
      subIndustry: "",
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/users/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.user = response.data.user;
          axios
            .get(process.env.VUE_APP_BASE_API + "/admin/campaigns?user_uuid=" + response.data.user.uuid, {
              headers: { Authorization: this.$store.getters.getToken },
            })
            .then((response) => {
              this.campaigns = response.data.campaigns;
              this.loading = false;
            });
          axios
            .get(process.env.VUE_APP_BASE_API + "/admin/devices?user_uuid=" + response.data.user.uuid, {
              headers: { Authorization: this.$store.getters.getToken },
            })
            .then((response) => {
              this.devices = response.data.devices;
              this.devices_loading = false;
            });
          axios
            .get(process.env.VUE_APP_BASE_API + "/admin/advertisments?user_uuid=" + response.data.user.uuid, {
              headers: { Authorization: this.$store.getters.getToken },
            })
            .then((response) => {
              this.advertisments = response.data.advertisments;
              this.ads_loading = false;
            });
          axios
            .get(process.env.VUE_APP_BASE_API + "/admin/playlists?user_uuid=" + response.data.user.uuid, {
              headers: { Authorization: this.$store.getters.getToken },
            })
            .then((response) => {
              this.playlists = response.data.playlists;
              this.playlists_loading = false;
            });
          axios
            .get(process.env.VUE_APP_BASE_API + "/admin/schedules?user_uuid=" + response.data.user.uuid, {
              headers: { Authorization: this.$store.getters.getToken },
            })
            .then((response) => {
              this.schedules = response.data.schedules;
              this.schedules_loading = false;
            });
          axios
            .get(process.env.VUE_APP_BASE_API + "/industries", {
              headers: { Authorization: this.$store.getters.getToken },
            })
            .then((response) => {
              this.industries = response.data.industries;

              this.subIndustry = this.user.industry_id;
              // get parent industry id
              this.industry = this.industries.flatMap((f) => f.industries).find((x) => x.id === this.subIndustry).industry_id;
              // get current subindustries based on parent id
              this.subIndustries = this.industries.find((x) => x.id === this.industry).industries;
            });
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/users", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.users = response.data.users;
        });
    },
    update_user: function (e) {
      e.preventDefault();
      this.user.industry_id = this.subIndustry;

      axios
        .patch(process.env.VUE_APP_BASE_API + "/admin/users/" + this.$route.params.uuid, this.user, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich gespeichert.",
          });
          this.get_data();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    onChange: function () {
      this.subIndustries = this.industries.find((x) => x.id === this.industry).industries;
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
